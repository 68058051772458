import React, { useState, useEffect, useRef } from "react";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Track login status
  const [mobileNumber, setMobileNumber] = useState(""); // Store mobile number
  const [isMainScreenVisible, setIsMainScreenVisible] = useState(false); // Track main screen visibility
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0); // Index for the video queue
  const [isFullscreen, setIsFullscreen] = useState(false); // Track fullscreen state
  const videoContainerRef = useRef(null); // Reference to the video container
  const videoRef = useRef(null); // Reference to the video element

  // Example video URLs, replace these with your own video sources
  const videoQueue = [
    "https://www.w3schools.com/html/mov_bbb.mp4", // Example video 1
    "https://www.w3schools.com/html/movie.mp4",   // Example video 2
    "https://www.w3schools.com/html/horse.mp4",    // Example video 3
  ];

  // Function to request fullscreen
  const requestFullScreen = () => {
    const container = videoContainerRef.current;
    if (container && container.requestFullscreen) {
      container.requestFullscreen();
      setIsFullscreen(true);
    } else if (container && container.mozRequestFullScreen) {
      container.mozRequestFullScreen();
      setIsFullscreen(true);
    } else if (container && container.webkitRequestFullscreen) {
      container.webkitRequestFullscreen();
      setIsFullscreen(true);
    } else if (container && container.msRequestFullscreen) {
      container.msRequestFullscreen();
      setIsFullscreen(true);
    }
  };

  // Function to exit fullscreen
  const exitFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
      setIsFullscreen(false);
    } else if (document.webkitFullscreenElement) {
      document.webkitExitFullscreen();
      setIsFullscreen(false);
    } else if (document.mozFullScreenElement) {
      document.mozCancelFullScreen();
      setIsFullscreen(false);
    } else if (document.msFullscreenElement) {
      document.msExitFullscreen();
      setIsFullscreen(false);
    }
  };

  // Handle the video end and move to the next video
  const handleVideoEnd = () => {
    setCurrentVideoIndex((prevIndex) =>
      prevIndex + 1 < videoQueue.length ? prevIndex + 1 : 0 // Loop back to first video if at the end
    );
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play(); // Ensure the new video is playing
    }
  }, [currentVideoIndex]); // Trigger video play when currentVideoIndex changes

  // Handle login form submission
  const handleLogin = (event) => {
    event.preventDefault();
    // Simple validation for mobile number (10 digits)
    const mobileRegex = /^[0-9]{10}$/;
    if (mobileRegex.test(mobileNumber)) {
      setIsLoggedIn(true); // Set login state to true if valid mobile number

      // Show main screen for 3 seconds before showing the video screen
      setIsMainScreenVisible(true);
      setTimeout(() => {
        setIsMainScreenVisible(false);
      }, 3000); // Hide main screen after 3 seconds
    } else {
      alert("Please enter a valid mobile number.");
    }
  };

  return (
    <div style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      {/* Show Login Screen if Not Logged In */}
      {!isLoggedIn ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100vh",
            backgroundColor: "black",
            color: "white",
          }}
        >
          <h2>Login</h2>
          <form onSubmit={handleLogin}>
            <input
              type="text"
              placeholder="Enter Mobile Number"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              style={{
                padding: "10px",
                fontSize: "16px",
                marginBottom: "10px",
                width: "200px",
                textAlign: "center",
              }}
            />
            <button
              type="submit"
              style={{
                padding: "10px 20px",
                fontSize: "16px",
                backgroundColor: "#4CAF50",
                color: "white",
                border: "none",
                cursor: "pointer",
              }}
            >
              Login
            </button>
          </form>
        </div>
      ) : (
        // Show Main Screen for 3 seconds after login
        <>
          {isMainScreenVisible && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                backgroundColor: "black",
                color: "white",
              }}
            >
              <h1>Welcome to the Pharmaplus Network</h1>
            </div>
          )}

          {/* Video Player Screen */}
          {!isMainScreenVisible && (
            <div
              ref={videoContainerRef}
              style={{
                width: "100%",
                height: "100vh",
                backgroundColor: "black",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <video
                ref={videoRef}
                width="100%"
                height="100%"
                controls={false} // No controls (You can add custom controls if needed)
                autoPlay
                muted
                onEnded={handleVideoEnd} // Move to the next video after current one ends
                style={{
                  objectFit: "cover",
                }}
              >
                <source src={videoQueue[currentVideoIndex]} type="video/mp4" />
                Your browser does not support the video tag.
              </video>

              {/* Fullscreen and Exit Fullscreen Buttons */}
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  zIndex: 10,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <button
                  onClick={isFullscreen ? exitFullScreen : requestFullScreen}
                  style={{
                    margin: "10px",
                    padding: "10px",
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    color: "white",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  {isFullscreen ? "Exit Fullscreen" : "Go Fullscreen"}
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default App;
